import { useGlobalState } from "@app/contexts"
import * as React from "react"

function useSetHeroHeight() {
  const heroRef = React.useRef<HTMLElement | null>(null)

  function setHeight() {
    if (heroRef.current) {
      heroRef.current.style.height = `${document.documentElement.clientHeight}px`
    }
  }

  const {
    userAgent: { isMobile, isTablet },
  } = useGlobalState()

  React.useEffect(() => {
    if (isMobile || isTablet) {
      setHeight()
      window.addEventListener("resize", setHeight)
    }

    return () => window.removeEventListener("resize", setHeight)
  }, [isMobile, isTablet])

  return heroRef
}

export { useSetHeroHeight }
