import cn from "clsx"

import styles from "./lists.module.scss"

const Lists = ({ visibility, className, children }) => {
  return (
    <ul
      className={cn(styles["root"], className, {
        [styles["visible"]]: visibility,
      })}
    >
      {children}
    </ul>
  )
}

export { Lists }
