import React from "react"
import PropTypes from "prop-types"
import { Link as AnchorLink } from "react-scroll"

import styles from "./anchor.module.scss"

function Anchor({ id, name, onClick, className }) {
  const [headerHeight, setHeaderHeight] = React.useState(null)

  React.useEffect(() => {
    const header = document.querySelector("#header")
    if (header) {
      const currentHeaderHeight = header.getBoundingClientRect().height
      setHeaderHeight(currentHeaderHeight)
    }
  }, [])

  return (
    <AnchorLink
      key={id}
      className={className}
      activeClass={styles["active"]}
      to={id}
      hashSpy={false}
      offset={-headerHeight}
      spy={true}
      smooth={true}
      isDynamic={true}
      duration={500}
      onClick={onClick}
    >
      {name}
    </AnchorLink>
  )
}

Anchor.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export { Anchor }
