import React from "react"

const LARGE_SCREEN_BREAKPOINT = "992px"

function useShowMoreButtonCalulator(scenes) {
  const [scenesToDisplay, setScenesToDisplay] = React.useState(scenes)
  const [isScenesWidthCalculated, setIsScenesWidthCalculated] = React.useState(false)
  const [isMoreButtonShouldBeRender, setIsMoreButtonShouldBeRender] = React.useState(false)

  const scenesContainerRef = React.useRef()
  const scenesForCalculationRef = React.useRef()

  React.useEffect(() => {
    setScenesToDisplay(scenes)
  }, [scenes])

  React.useEffect(() => {
    if (window.matchMedia(`(min-width: ${LARGE_SCREEN_BREAKPOINT})`).matches) {
      const scenesContainerWidth = scenesContainerRef.current?.getBoundingClientRect().width
      const scenesForCalculationWidth = scenesForCalculationRef.current?.getBoundingClientRect().width
      let menuItemsToDisplayCounter = []
      let menuItemsToDisplayWidthCounter = 0
      if (scenesForCalculationWidth > scenesContainerWidth) {
        const menuItems = scenesForCalculationRef.current?.childNodes
        menuItems.forEach((item) => {
          const currentMenuItemsCounterValue = menuItemsToDisplayWidthCounter + item.getBoundingClientRect().width
          if (currentMenuItemsCounterValue < scenesContainerWidth) {
            menuItemsToDisplayWidthCounter += item.getBoundingClientRect().width
            menuItemsToDisplayCounter.push(item)
          }
        })

        setScenesToDisplay(scenesToDisplay.slice(0, menuItemsToDisplayCounter.length - 1))
        setIsMoreButtonShouldBeRender(true)
      } else {
        setIsScenesWidthCalculated(true)
      }
    } else {
      setIsScenesWidthCalculated(true)
    }
  }, [scenesToDisplay])

  return {
    scenesToDisplay,
    isScenesWidthCalculated,
    isMoreButtonShouldBeRender,
    scenesContainerRef,
    scenesForCalculationRef,
  }
}

export { useShowMoreButtonCalulator }
