import React from "react"
import { useI18n } from "next-localization"
import cx from "clsx"

import { useGallery } from "@app/contexts"
import { useShowMoreButtonCalulator } from "features/gallery/components/header/hooks"

import { ShowMore } from "../show-more"
import { Anchor } from "ui/pages/gallery/anchor"

import { QA_CLASS_NAMES } from "@app/constants"

import styles from "./scenes.module.scss"

function Scenes({
  scenes,
  mobileMenuRef,
  handleScrollMobileMenu,
  isWithoutScenes,
  isHasHidingMediaFile,
  isCanBeHidden,
}) {
  const i18n = useI18n()
  const { gallery } = useGallery()

  const [scenesUpdate, setScenesUpdate] = React.useState(scenes)
  React.useEffect(() => {
    setScenesUpdate(gallery.scenes)
  }, [gallery])

  const {
    scenesToDisplay,
    isScenesWidthCalculated,
    isMoreButtonShouldBeRender,
    scenesContainerRef,
    scenesForCalculationRef,
  } = useShowMoreButtonCalulator(scenesUpdate)

  function AnchorScene(item, index) {
    const isShow =
      (isCanBeHidden && item.mediaFiles.length) ||
      (isCanBeHidden && item.isAllMediaFilesHidden && !item.mediaFiles.length) ||
      (item.mediaFiles.length && !item.isAllMediaFilesHidden)
    if (isShow) {
      return (
        <li className={styles["scene"]} key={item.id}>
          <Anchor {...item} className={cx(styles["anchor"], `${QA_CLASS_NAMES.header.scenes.item}${index}`)}></Anchor>
        </li>
      )
    }
  }

  return (
    <React.Fragment>
      {!isScenesWidthCalculated && !isWithoutScenes && (
        <ul
          ref={scenesContainerRef}
          className={cx(styles["scenes"], {
            [styles["hidden"]]: !isScenesWidthCalculated,
          })}
        ></ul>
      )}

      {!isScenesWidthCalculated && !isWithoutScenes && (
        <ul
          ref={scenesForCalculationRef}
          className={cx(styles["scenes"], {
            [styles["hidden"]]: !isScenesWidthCalculated,
            [styles["position-absolute"]]: !isScenesWidthCalculated,
          })}
        >
          {scenesToDisplay.map((item, index) => {
            return AnchorScene(item, index)
          })}
          {isHasHidingMediaFile && (
            <li className={styles["scene"]}>
              <Anchor
                id="hidden-photos-01"
                name={i18n.t("common.hiddenPhotos")}
                className={cx(styles["anchor"], QA_CLASS_NAMES.header.scenes.hidden)}
              ></Anchor>
            </li>
          )}
          <ShowMore
            className={styles["show-more"]}
            data={scenesUpdate.slice(scenesToDisplay.length, scenesUpdate.length)}
            isCanBeHidden={isCanBeHidden}
          ></ShowMore>
        </ul>
      )}

      {isScenesWidthCalculated && !isWithoutScenes && (
        <div className={styles["wrapper"]} ref={mobileMenuRef} onScroll={handleScrollMobileMenu}>
          <ul className={styles["scenes"]}>
            {scenesToDisplay.map((item, index) => {
              return AnchorScene(item, index)
            })}

            {!isMoreButtonShouldBeRender && isHasHidingMediaFile && (
              <li className={styles["scene"]}>
                <Anchor
                  id="hidden-photos-01"
                  name={i18n.t("common.hiddenPhotos")}
                  className={cx(styles["anchor"], QA_CLASS_NAMES.header.scenes.hidden)}
                ></Anchor>
              </li>
            )}
            {isMoreButtonShouldBeRender && (
              <ShowMore
                className={styles["show-more"]}
                data={scenesUpdate.slice(scenesToDisplay.length, scenesUpdate.length)}
                isHasHidingMediaFile={isHasHidingMediaFile}
                hiddenSceneName={i18n.t("common.hiddenPhotos")}
              ></ShowMore>
            )}
          </ul>
        </div>
      )}

      {isWithoutScenes && (
        <div ref={mobileMenuRef} onScroll={handleScrollMobileMenu}>
          <ul className={styles["scenes"]}>
            {isHasHidingMediaFile && (
              <li className={styles["scene"]}>
                <Anchor
                  id="hidden-photos-01"
                  name={i18n.t("common.hiddenPhotos")}
                  className={cx(styles["anchor"], QA_CLASS_NAMES.header.scenes.hidden)}
                ></Anchor>
              </li>
            )}
          </ul>
        </div>
      )}
    </React.Fragment>
  )
}

export { Scenes }
