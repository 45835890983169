import React from "react"
import cx from "clsx"
import { CSSTransition } from "react-transition-group"
import { useI18n } from "next-localization"

import { scrollToTarget } from "features/scroll/utils"

import { Cross, ArrowLeft } from "ui/icons"
import { IconWrapper } from "features/theme/icon-wrapper"

import { useGlobalState } from "@app/contexts"
import { useHidingPhotosAuth } from "@app/features/user/stores"

import { QA_CLASS_NAMES } from "@app/constants"

import styles from "./mobile-menu-scenes.module.scss"

function MobileMenuScenes({ visible, toggleMobileMenuVisibility, toggleMobileSubmenuVisibility, scenes }) {
  const [visibleScenes, setVisibleScenes] = React.useState([])

  function handleBackClick() {
    toggleMobileMenuVisibility()
    toggleMobileSubmenuVisibility()
  }

  const { isHidingPhotosEnabled, isGrantedHidingPhotos } = useGlobalState()

  const { isUserAuthorizedHidingPhotos, setUserAuthorizationHidingPhotos } = useHidingPhotosAuth()

  React.useEffect(() => {
    if (isHidingPhotosEnabled && !isUserAuthorizedHidingPhotos) {
      setVisibleScenes(scenes.filter((scene) => scene.isAllMediaFilesHidden === false))
    } else {
      setVisibleScenes(scenes)
    }
  }, [isHidingPhotosEnabled, isUserAuthorizedHidingPhotos, scenes])

  React.useEffect(() => {
    isHidingPhotosEnabled && setUserAuthorizationHidingPhotos(isGrantedHidingPhotos)
  }, [isHidingPhotosEnabled, isGrantedHidingPhotos])

  const [isHeaderActive, setIsHeaderActive] = React.useState(false)
  const listRef = React.useRef()
  function handleScroll() {
    const listOffsetTop = listRef.current?.getBoundingClientRect().top
    const shouldBeHeaderActive = 70
    if (listOffsetTop < shouldBeHeaderActive) {
      setIsHeaderActive(true)
      return
    }
    setIsHeaderActive(false)
  }

  function handleAnchorClick(sceneId) {
    toggleMobileSubmenuVisibility()
    scrollToTarget(sceneId)
  }
  const i18n = useI18n()

  return (
    <CSSTransition
      in={visible}
      timeout={{
        enter: 350,
        exit: 100,
      }}
      classNames={{
        enter: styles["enter-animation"],
        enterActive: styles["enter-animation-active"],
        enterDone: styles["enter-animation-done"],
        exit: styles["exit-animation"],
        exitActive: styles["exit-animation-active"],
        exitDone: styles["exit-animation-done"],
      }}
      unmountOnExit
    >
      <nav className={styles["navigation"]} onScroll={handleScroll}>
        <header
          className={cx(styles["header"], {
            [styles["active"]]: isHeaderActive,
          })}
        >
          <button className={cx(styles["go-back"], QA_CLASS_NAMES.header.scenes.back)} onClick={handleBackClick}>
            <IconWrapper
              light={<ArrowLeft width="33" height="15" fill="#000" />}
              dark={<ArrowLeft width="33" height="15" fill="#fff" />}
            />
          </button>
          <button
            className={cx(styles["close"], QA_CLASS_NAMES.header.scenes.close)}
            onClick={toggleMobileSubmenuVisibility}
          >
            <IconWrapper
              light={<Cross width={16} height={16} stroke="#000" />}
              dark={<Cross width={16} height={16} stroke="#fff" />}
            />
          </button>
        </header>
        <ul className={styles["navigation-items"]} ref={listRef}>
          <li className={styles["item"]}>
            <span className={styles["subtitle"]}>{i18n.t("galleryPage.sceneList")}</span>
          </li>
          {visibleScenes.map((scene, index) => (
            <li className={styles["item"]} key={index}>
              <span
                onClick={() => {
                  handleAnchorClick(scene.id)
                }}
                className={cx(styles["anchor"], `${QA_CLASS_NAMES.header.scenes.item}${index}`)}
              >
                {scene.name}
              </span>
            </li>
          ))}
        </ul>
      </nav>
    </CSSTransition>
  )
}

export { MobileMenuScenes }
