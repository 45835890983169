import { useGalleryStore } from "features/gallery/store"

const isMobileMenuVisibleSelector = (state) => state.isMobileMenuVisible
const isMobileSubmenuVisibleSelector = (state) => state.isMobileSubmenuVisible
const toggleMobileMenuVisibilitySelector = (state) => state.toggleMobileMenuVisibility
const toggleMobileSubmenuVisibilitySelector = (state) => state.toggleMobileSubmenuVisibility
const hideMobileMenuSelector = (state) => state.hideMobileMenu

function useMobileMenu() {
  const $isMobileMenuVisible = useGalleryStore(isMobileMenuVisibleSelector)
  const $isMobileSubmenuVisible = useGalleryStore(isMobileSubmenuVisibleSelector)
  const $toggleMobileMenuVisibility = useGalleryStore(toggleMobileMenuVisibilitySelector)
  const $toggleMobileSubmenuVisibility = useGalleryStore(toggleMobileSubmenuVisibilitySelector)
  const $hideMobileMenu = useGalleryStore(hideMobileMenuSelector)

  return {
    $isMobileMenuVisible,
    $isMobileSubmenuVisible,
    $toggleMobileMenuVisibility,
    $toggleMobileSubmenuVisibility,
    $hideMobileMenu,
  }
}

export { useMobileMenu }
