import React from "react"

import {
  PARALLAX_TEXT_MOVE_MULTIPLIER,
  PARALLAX_PICTURE_MOVE_MULTIPLIER,
  DEFAULT_ABSOLUTE_CENTERING,
  HERO_IMAGE_PROPORTION,
  VERTICAL_PARALLAX_MOVE_MULTIPLIER,
} from "features/hero/constants"
import { isMobileDevice as isMobile } from "@app/utils"

function useParallaxAnimation(image, heroRef) {
  const areaContainerRef = React.useRef<HTMLDivElement>(null)
  const invertedContainerRef = React.useRef<HTMLDivElement>(null)
  const invertedTitleRef = React.useRef<HTMLHeadingElement>(null)
  const titleRef = React.useRef<HTMLHeadingElement>(null)
  const compositionRef = React.useRef<HTMLDivElement>(null)
  const coverContainerRef = React.useRef<HTMLDivElement>(null)

  function isFirstScreenVisible() {
    return window.scrollY < document.documentElement.clientHeight
  }

  function mobileParallaxAnimation(element: HTMLElement): void {
    if (isFirstScreenVisible()) {
      const translatePropertyTitle: string = `translate3d(0px, ${
        window.scrollY * VERTICAL_PARALLAX_MOVE_MULTIPLIER
      }px, 0px )`
      const isElementExist = Boolean(element)
      if (isElementExist) {
        element.style.transform = `${DEFAULT_ABSOLUTE_CENTERING} ${translatePropertyTitle}`
      }
    }
  }

  function handleMobileAnimation(): void {
    mobileParallaxAnimation(titleRef.current)
  }

  function getTranslateValue(axisOffset: number, moveMultiplier: number) {
    return Math.round(axisOffset * moveMultiplier)
  }

  function desctopParallaxAnimation(event: React.MouseEvent): void {
    const centerWindowX: number = document.documentElement.clientWidth / 2
    const centerWindowY: number = document.documentElement.clientHeight / 2
    const shiftCursorX: number = event.pageX - centerWindowX
    const shiftCursorY: number = event.pageY - centerWindowY
    const offsetX: number = -shiftCursorX / 100
    const offsetY: number = -shiftCursorY / 100

    const titleTranslateXValue: number = getTranslateValue(offsetX, PARALLAX_TEXT_MOVE_MULTIPLIER)
    const titleTranslateYValue: number = getTranslateValue(offsetY, PARALLAX_TEXT_MOVE_MULTIPLIER)
    const translatePropertyTitle: string = `translate3d(${titleTranslateXValue}px, ${titleTranslateYValue}px, 0px)`

    const pictureTranslateXValue: number = getTranslateValue(offsetX, PARALLAX_PICTURE_MOVE_MULTIPLIER)
    const pictureTranslateYValue: number = getTranslateValue(offsetY, PARALLAX_PICTURE_MOVE_MULTIPLIER)
    const translatePropertyPicture: string = `translate3d(${pictureTranslateXValue}px, ${pictureTranslateYValue}px, 0px)`

    invertedTitleRef.current.style.transform = `${DEFAULT_ABSOLUTE_CENTERING} ${translatePropertyTitle}`
    titleRef.current.style.transform = `${DEFAULT_ABSOLUTE_CENTERING} ${translatePropertyTitle}`
    compositionRef.current.style.transform = `${translatePropertyPicture}`
  }

  function handleDesctopAnimation(event: React.MouseEvent): void {
    desctopParallaxAnimation(event)
  }

  function setProportionalContainersWidth(): void {
    const areaHeight: number = areaContainerRef?.current?.clientHeight
    const maxImageWidth: number = Math.round(areaHeight * HERO_IMAGE_PROPORTION)
    invertedContainerRef.current.style.width = `${maxImageWidth}px`
    coverContainerRef.current.style.width = `${maxImageWidth}px`
  }

  function normolizeAnimatedElements(): void {
    invertedContainerRef.current.style.removeProperty("width")
    coverContainerRef.current.style.removeProperty("width")
    titleRef.current.style.transform = `${DEFAULT_ABSOLUTE_CENTERING} translate3d(0px, 0px, 0px)`
    invertedTitleRef.current.style.transform = `${DEFAULT_ABSOLUTE_CENTERING} translate3d(0px, 0px, 0px)`
  }

  function handleAnimationEvents(): void {
    const isMobileDevice: boolean = isMobile()
    if (!isMobileDevice) {
      setProportionalContainersWidth()
      heroRef.current.addEventListener("mousemove", handleDesctopAnimation)
      window.addEventListener("resize", setProportionalContainersWidth)
      window.removeEventListener("scroll", handleMobileAnimation)
    }
    if (isMobileDevice) {
      normolizeAnimatedElements()
      window.addEventListener("scroll", handleMobileAnimation)
      heroRef.current.removeEventListener("mousemove", handleDesctopAnimation)
      window.removeEventListener("resize", setProportionalContainersWidth)
    }
  }

  React.useEffect(() => {
    handleAnimationEvents()
    window.addEventListener("resize", handleAnimationEvents)

    return () => {
      window.removeEventListener("resize", handleAnimationEvents)
      window.removeEventListener("resize", setProportionalContainersWidth)
      window.removeEventListener("scroll", handleMobileAnimation)
    }
  }, [])

  return {
    areaContainerRef,
    invertedContainerRef,
    invertedTitleRef,
    titleRef,
    compositionRef,
    coverContainerRef,
    desctopParallaxAnimation,
  }
}

export { useParallaxAnimation }
