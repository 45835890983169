import React from "react"
import cx from "clsx"
import { useI18n } from "next-localization"

import { getMeta } from "@app/features/meta/utils"
import { PASSWORD_SCREEN_META_KEY } from "@app/features/meta/constants"
import { Meta } from "@app/features/meta/components"
import { getCoverPositionInlineStyle, getCoverURLBySize, getResponsiveSizesString } from "@app/features/cover/utils"
import { useSetHeroHeight } from "@app/features/hero/hooks"
import { useGallery } from "@app/contexts"
import { useGlobalState } from "@app/contexts"
import { fetchGallery } from "@app/features/gallery/requests"
import { REQUEST_TYPE } from "@app/api/constants"
import { setGalleryPasswordToCookies } from "@app/features/gallery/utils"

import { Button } from "@app/ui/button"
import { EyeOff, EyeOn } from "@app/ui/icons"

import { CoverImageSources } from "../cover-image-sources"
import { GoogleImage } from "@app/features/google-image-service-status/components/google-image"

import { QA_CLASS_NAMES } from "@app/constants"

import styles from "./password-screen.module.scss"

const PasswordScreen = ({ onSubmitCorrectPassword }) => {
  const { gallery, setGallery } = useGallery()
  const settings = useGlobalState()
  const { isIos } = settings.userAgent
  const { domain, slug } = settings
  const [isLoading, setIsLoading] = React.useState(false)

  const cover = gallery.cover
  const coverPosition = gallery.settings.coverPosition

  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false)
  const [isCorrectPassword, setIsCorrectPassword] = React.useState(true)

  function togglePasswordVisibility() {
    setIsPasswordVisible(!isPasswordVisible)
  }

  const [password, setPassowrd] = React.useState("")
  function handlePasswordChange(event) {
    setPassowrd(event.target.value)
    setIsCorrectPassword(true)
  }

  async function handleSubmit(event) {
    event.preventDefault()
    setIsLoading(true)

    // Пароль не введен
    if (!password.trim()) {
      setIsCorrectPassword(false)
      setIsLoading(false)

      return
    }

    const { isPasswordProtected, gallery } = await fetchGallery({
      requestType: REQUEST_TYPE.CLIENT,
      domain,
      slug,
      password,
      ctx: null,
    })

    // Пароль введен неверно
    if (isPasswordProtected) {
      setIsCorrectPassword(false)
      setIsLoading(false)

      return
    }

    // Пароль введен верно
    if (!isPasswordProtected && gallery) {
      setGallery(gallery)
      onSubmitCorrectPassword()
      setGalleryPasswordToCookies(domain, slug, password)
    }

    setIsLoading(false)
  }

  const i18n = useI18n()
  const ref = useSetHeroHeight()

  return (
    <React.Fragment>
      <style jsx global>{`
        body {
          min-height: initial;
        }
      `}</style>
      <Meta {...getMeta({ key: PASSWORD_SCREEN_META_KEY, gallery, settings, i18n })}></Meta>
      <section className={cx(styles["password-screen"])} ref={ref}>
        {cover && (
          <picture>
            <CoverImageSources cover={cover} />
            <GoogleImage
              src={getCoverURLBySize(cover, "l")}
              alt=""
              className={styles["cover"]}
              style={getCoverPositionInlineStyle(coverPosition)}
            />
          </picture>
        )}
        <div className={styles["container"]}>
          <form className={styles["form"]} onSubmit={handleSubmit}>
            <h1 className={styles["title"]}>{gallery.name}</h1>
            <p className={styles["prompt"]}>{i18n.t("passwordPage.description")}</p>
            <label className={styles["label"]}>
              <input
                type={isPasswordVisible ? "text" : "password"}
                placeholder={i18n.t("passwordPage.placeholder")}
                className={cx(
                  styles["input"],
                  {
                    [styles["error"]]: !isCorrectPassword,
                    [styles["scalable-field"]]: isIos,
                  },
                  QA_CLASS_NAMES.gallery.password.input
                )}
                value={password}
                onChange={handlePasswordChange}
              ></input>
              <button
                className={cx(styles["show-password"], QA_CLASS_NAMES.gallery.password.show)}
                type="button"
                onClick={togglePasswordVisibility}
              >
                {isPasswordVisible && <EyeOff width="20" height="15" stroke="#fff"></EyeOff>}
                {!isPasswordVisible && <EyeOn width="20" height="15" stroke="#fff"></EyeOn>}
              </button>
            </label>
            {!isCorrectPassword && <p className={styles["error-message"]}>{i18n.t("passwordPage.errorMessage")}</p>}
            <Button
              loading={isLoading}
              type="submit"
              skin="inverted"
              className={cx(styles["button"], QA_CLASS_NAMES.gallery.password.button)}
            >
              {i18n.t("common.login")}
            </Button>
          </form>
          <footer className={styles["footer"]}>
            <p className={styles["copyright"]}>
              ©{new Date().getFullYear()} {settings.name}
            </p>
            <p className={styles["copyright"]}>
              <a
                href={`https://vigbo.com/galleries?utm_source=vigbo.gallery&utm_medium=footer&utm_campaign=copyright_gallery_lp`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Gallery by Vigbo
              </a>
            </p>
          </footer>
        </div>
      </section>
    </React.Fragment>
  )
}

export { PasswordScreen }
