import * as React from "react"

import { normalizeSiteURL, normalizeURL } from "@app/utils"
import { useGlobalState } from "@app/contexts"

import { Facebook, Insta, Vk, Telegram, Vimeo, Youtube } from "@app/ui/icons"
import { IconWrapper } from "@app/features/theme/icon-wrapper"

import styles from "./mobile-menu-contacts.module.scss"

const MobileMenuContacts = () => {
  const { phone, email, site, instagram, facebook, vkontakte, telegram, vimeo, youtube } = useGlobalState()

  return (
    <div className={styles["contacts"]}>
      {phone && (
        <a className={styles["contact"]} href={`tel:${phone}`}>
          {phone}
        </a>
      )}
      {email && (
        <a className={styles["contact"]} href={`mailto:${email}`}>
          {email}
        </a>
      )}
      {site && (
        <a className={styles["contact"]} href={normalizeURL(site)} target="_blank" rel="noopener noreferrer">
          {normalizeSiteURL(site)}
        </a>
      )}

      <div className={styles["social-networks"]}>
        {instagram && (
          <a
            className={styles["social-network"]}
            href={normalizeURL(instagram)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconWrapper light={<Insta width={26} height={26} />} dark={<Insta fill="#fff" width={26} height={26} />} />
          </a>
        )}

        {facebook && (
          <a
            className={styles["social-network"]}
            href={normalizeURL(facebook)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconWrapper
              light={<Facebook width={26} height={26} />}
              dark={<Facebook fill="#fff" width={26} height={26} />}
            />
          </a>
        )}

        {vkontakte && (
          <a
            className={styles["social-network"]}
            href={normalizeURL(vkontakte)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconWrapper light={<Vk width={26} height={26} />} dark={<Vk width={26} height={26} fill="#fff" />} />
          </a>
        )}

        {telegram && (
          <a
            className={styles["social-network"]}
            href={normalizeURL(telegram)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconWrapper
              light={<Telegram width={26} height={26} />}
              dark={<Telegram fill="#fff" width={26} height={26} />}
            />
          </a>
        )}

        {vimeo && (
          <a className={styles["social-network"]} href={normalizeURL(vimeo)} target="_blank" rel="noopener noreferrer">
            <IconWrapper light={<Vimeo width={26} height={26} />} dark={<Vimeo fill="#fff" width={26} height={26} />} />
          </a>
        )}

        {youtube && (
          <a
            className={styles["social-network"]}
            href={normalizeURL(youtube)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconWrapper
              light={<Youtube width={26} height={26} />}
              dark={<Youtube width={26} height={26} fill="#fff" />}
            />
          </a>
        )}
      </div>
    </div>
  )
}

export { MobileMenuContacts }
