import React, { ReactElement } from "react"

import { getResponsiveSizesString } from "@app/features/cover/utils"
import { GoogleImageServiceStatusContext } from "@app/features/google-image-service-status/contexts"
import { useGoogleImageSrc } from "@app/features/google-image-service-status/hooks/use-google-image-src"

interface CoverImageSourcesProps {
  cover: string
}

const CoverImageSources = ({ cover }: CoverImageSourcesProps): ReactElement => {
  const { isAvailable: isGoogleServiceAvailable, postfix } = React.useContext(GoogleImageServiceStatusContext)

  const coverSizeM = useGoogleImageSrc(
    getResponsiveSizesString({ cover, size: "m", isGoogleServiceAvailable, imgFormat: postfix }),
    isGoogleServiceAvailable
  )
  const coverSizeL = useGoogleImageSrc(
    getResponsiveSizesString({ cover, size: "l", isGoogleServiceAvailable, imgFormat: postfix }),
    isGoogleServiceAvailable
  )
  const coverSizeXl = useGoogleImageSrc(
    getResponsiveSizesString({ cover, size: "xl", isGoogleServiceAvailable, imgFormat: postfix }),
    isGoogleServiceAvailable
  )
  const coverSizeXxl = useGoogleImageSrc(
    getResponsiveSizesString({ cover, size: "xxl", isGoogleServiceAvailable, imgFormat: postfix }),
    isGoogleServiceAvailable
  )

  return (
    <React.Fragment>
      <source media="(max-width: 768px)" srcSet={coverSizeM}></source>
      <source media="(max-width: 1440px)" srcSet={coverSizeL}></source>
      <source media="(max-width: 1920px)" srcSet={coverSizeXl}></source>
      <source media="(min-width: 1920px)" srcSet={coverSizeXxl}></source>
    </React.Fragment>
  )
}

export { CoverImageSources }
