const PARALLAX_TEXT_MOVE_MULTIPLIER: number = 7
const PARALLAX_PICTURE_MOVE_MULTIPLIER: number = 2
const DEFAULT_ABSOLUTE_CENTERING: string = "translate(-50%, -50%)"
const HERO_IMAGE_PROPORTION: number = 0.6666666667
const VERTICAL_PARALLAX_MOVE_MULTIPLIER: number = 0.1

export {
  PARALLAX_TEXT_MOVE_MULTIPLIER,
  PARALLAX_PICTURE_MOVE_MULTIPLIER,
  DEFAULT_ABSOLUTE_CENTERING,
  HERO_IMAGE_PROPORTION,
  VERTICAL_PARALLAX_MOVE_MULTIPLIER,
}
