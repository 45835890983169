import React from "react"

function useAnimateCover() {
  const imageRef = React.useRef()
  const [isCoverLoaded, setIsCoverLoaded] = React.useState(false)

  React.useEffect(() => {
    if (!imageRef.current || imageRef.current?.complete) {
      setIsCoverLoaded(true)
    }
  }, [setIsCoverLoaded])

  return {
    isCoverLoaded,
    image: {
      ref: imageRef,
      onLoad: () => setIsCoverLoaded(true),
      onError: () => setIsCoverLoaded(true),
    },
  }
}

export { useAnimateCover }
