import React from "react"

import { Lists } from "ui/lists"
import { Anchor } from "ui/pages/gallery/anchor"

import styles from "./show-more.module.scss"

function ShowMore({ onClick, className, data, isHasHidingMediaFile, hiddenSceneName, isCanBeHidden }) {
  const [isListsVisible, setListsVisible] = React.useState(false)
  function setListsVisibility() {
    setListsVisible(true)
  }
  function removeListsVisibility() {
    setListsVisible(false)
  }

  function AnchorScene(item, index) {
    const isShow =
      (isCanBeHidden && item.mediaFiles.length) ||
      (isCanBeHidden && item.isAllMediaFilesHidden && !item.mediaFiles.length) ||
      (item.mediaFiles.length && !item.isAllMediaFilesHidden)
    if (isShow) {
      return (
        <li className={styles["scene"]} key={index}>
          <Anchor
            id={item.id}
            name={item.name}
            slug={item.slug}
            onClick={onClick}
            className={styles["anchor"]}
          ></Anchor>
        </li>
      )
    }
  }

  return (
    <li className={className} onMouseEnter={setListsVisibility} onMouseLeave={removeListsVisibility}>
      <button type="button" className={styles["button"]}>
        ...
      </button>
      <Lists visibility={isListsVisible} className={styles["lists"]}>
        {data.map((item, index) => AnchorScene(item, index))}
        {isHasHidingMediaFile && (
          <li className={styles["scene"]}>
            <Anchor id="hidden-photos-01" name={hiddenSceneName} className={styles["anchor"]}></Anchor>
          </li>
        )}
      </Lists>
    </li>
  )
}

export { ShowMore }
