import create from "zustand"

const useGalleryStore = create((set) => ({
  isMobileMenuVisible: false,
  isMobileSubmenuVisible: false,
  toggleMobileMenuVisibility: () => set((state) => ({ isMobileMenuVisible: !state.isMobileMenuVisible })),
  toggleMobileSubmenuVisibility: () => set((state) => ({ isMobileSubmenuVisible: !state.isMobileSubmenuVisible })),
  hideMobileMenu: () => set(() => ({ isMobileMenuVisible: false, isMobileSubmenuVisible: false })),
}))

export { useGalleryStore }
