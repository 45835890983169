import React from "react"
import { CSSTransition } from "react-transition-group"
import { useI18n } from "next-localization"
import cx from "clsx"

import { useDownloadGallery } from "@app/features/download/hooks"
import { useFavoritesList } from "@app/features/favorites/hooks"
import { useSharing } from "@app/features/sharing/hooks"
import { MobileMenuContacts } from "@app/features/gallery/components/header/components"
import { useGallery, useGlobalState } from "@app/contexts"
import { trackYandexMetrikaAction } from "@app/features/analytics/utils"
import { sharesCountIncrement } from "@app/features/statistics"

import { useAuthorization } from "@app/features/user/stores"

import { AuthClientSignIn } from "@app/features/auth-client/components"
import { AuthClientSignOut } from "@app/features/auth-client/components"

import { Cross, Download, Like, Share, Picture, Printer } from "ui/icons"

import { IconWrapper } from "@app/features/theme/icon-wrapper"
import { COLORS } from "@app/constants"
import { useShowProductsModal } from "@app/features/products/hooks/products-modal"
import { PRODUCTS_MODAL_TYPES } from "@app/features/products/types"

import { QA_CLASS_NAMES } from "@app/constants"

import styles from "./mobile-menu-actions.module.scss"

function MobileMenuActions({
  visible,
  toggleMobileMenuVisibility,
  toggleMobileSubmenuVisibility,
  galleryId,
  isWithoutScenes,
  isDownloadEnabled,
  galleryName,
}) {
  const downloadGallery = useDownloadGallery(galleryId)
  const like = useFavoritesList({ isRedirectToFavoritesListPage: true })

  function handleClick() {
    toggleMobileMenuVisibility()
    toggleMobileSubmenuVisibility()
  }

  const i18n = useI18n()

  const share = useSharing()
  async function handleShareClick() {
    trackYandexMetrikaAction("click-share")
    share("gallery", {
      title: galleryName,
      url: `${window.location.origin}${window.location.pathname}`,
    })
    sharesCountIncrement(galleryId)
  }

  function handleDownloadAllPhotosClick() {
    downloadGallery()
    trackYandexMetrikaAction("click-download")
  }

  function handleLikeClick() {
    like()
    trackYandexMetrikaAction("click-menu-favorites-mobile")
  }

  const { isFavoritesSectionActivated, isHidingPhotosEnabled } = useGlobalState()

  const { gallery } = useGallery()
  const { isUserAuthorized } = useAuthorization()
  const $showProductsModal = useShowProductsModal()
  const isPrintableProductsEnabled = gallery?.isPrintableProductsEnabled

  return (
    <React.Fragment>
      <CSSTransition
        in={visible}
        timeout={{
          enter: 350,
          exit: 100,
        }}
        classNames={{
          enter: styles["enter-animation"],
          enterActive: styles["enter-animation-active"],
          enterDone: styles["enter-animation-done"],
          exit: styles["exit-animation"],
          exitActive: styles["exit-animation-active"],
          exitDone: styles["exit-animation-done"],
        }}
        unmountOnExit
      >
        <nav className={styles["navigation"]}>
          <header className={styles["header"]}>
            <button
              className={cx(styles["close"], QA_CLASS_NAMES.header.burger.close)}
              onClick={toggleMobileMenuVisibility}
            >
              <IconWrapper
                light={<Cross width={16} height={16} stroke={COLORS.black} />}
                dark={<Cross width={16} height={16} stroke={COLORS.white} />}
              />
            </button>
          </header>
          <ul className={styles["navigation-items"]}>
            {isPrintableProductsEnabled && (
              <li className={styles["item"]}>
                <button
                  className={cx(styles["button"], QA_CLASS_NAMES.header.print)}
                  onClick={() => $showProductsModal(PRODUCTS_MODAL_TYPES.DEFAULT)}
                >
                  <IconWrapper
                    light={
                      <Printer width="20" height="21" stroke={COLORS.smokeScreen} className={styles["mark-icon"]} />
                    }
                    dark={<Printer width="20" height="21" stroke={COLORS.nickel} className={styles["mark-icon"]} />}
                  />
                  {i18n.t("common.printPhoto")}
                </button>
                <img
                  onClick={() => $showProductsModal(PRODUCTS_MODAL_TYPES.DEFAULT)}
                  className={styles["image"]}
                  src={"/img/product/banner/vigbo-print-products.png"}
                  alt={"Print photos"}
                />
              </li>
            )}

            {!isWithoutScenes && (
              <li className={styles["item"]}>
                <button className={cx(styles["button"], QA_CLASS_NAMES.header.scenes.list)} onClick={handleClick}>
                  <IconWrapper
                    light={
                      <Picture width="18" height="18" stroke={COLORS.smokeScreen} className={styles["mark-icon"]} />
                    }
                    dark={<Picture width="18" height="18" stroke={COLORS.nickel} className={styles["mark-icon"]} />}
                  />
                  {i18n.t("galleryPage.sceneList")}
                </button>
              </li>
            )}

            {isDownloadEnabled && (
              <li className={styles["item"]}>
                <button
                  className={cx(styles["button"], QA_CLASS_NAMES.header.download)}
                  onClick={handleDownloadAllPhotosClick}
                >
                  <IconWrapper
                    light={
                      <Download
                        width="18"
                        height="18"
                        stroke={COLORS.smokeScreen}
                        className={styles["mark-icon"]}
                      ></Download>
                    }
                    dark={
                      <Download
                        width="18"
                        height="18"
                        stroke={COLORS.nickel}
                        className={styles["mark-icon"]}
                      ></Download>
                    }
                  />
                  {i18n.t("common.downloadAllPhotos")}
                </button>
              </li>
            )}
            <li className={styles["item"]}>
              <button className={cx(styles["button"], QA_CLASS_NAMES.header.share)} onClick={handleShareClick}>
                <IconWrapper
                  light={<Share width="21" height="17" fill={COLORS.smokeScreen} className={styles["mark-icon"]} />}
                  dark={<Share width="21" height="17" fill={COLORS.nickel} className={styles["mark-icon"]} />}
                />
                {i18n.t("common.share")}
              </button>
            </li>
            {isFavoritesSectionActivated && (
              <li className={styles["item"]}>
                <button className={cx(styles["button"], QA_CLASS_NAMES.header.favourites)} onClick={handleLikeClick}>
                  <IconWrapper
                    light={<Like width="18" height="16" stroke={COLORS.smokeScreen} className={styles["mark-icon"]} />}
                    dark={<Like width="18" height="16" stroke={COLORS.nickel} className={styles["mark-icon"]} />}
                  />
                  {i18n.t("common.favorites")}
                </button>
              </li>
            )}

            {isHidingPhotosEnabled && !isUserAuthorized && (
              <li className={cx(styles["item"], styles["item-bottom"])}>
                <AuthClientSignIn
                  buttonClassName={styles["button-auth"]}
                  iconClassName={styles["mark-icon"]}
                  strokeLight={COLORS.smokeScreen}
                  strokeDark={COLORS.smokeScreen}
                >
                  {i18n.t("common.login")}
                </AuthClientSignIn>
              </li>
            )}
            {isUserAuthorized && (
              <li className={cx(styles["item"], styles["item-bottom"])}>
                <AuthClientSignOut
                  modalText={i18n.t("hidingPhotosModal.signOutDescription")}
                  modalApprove={i18n.t("common.signOut")}
                  modalCancel={i18n.t("common.cancel")}
                  modalPosition="down-right"
                  isMobileType={true}
                  buttonClassName={cx(styles["button-auth"], styles["button-signed"])}
                  iconClassName={styles["mark-icon"]}
                  strokeLight={COLORS.smokeScreen}
                  strokeDark={COLORS.smokeScreen}
                  fillLight={COLORS.white}
                  fillDark={COLORS.black}
                  fillSecond={COLORS.smokeScreen}
                >
                  {i18n.t("common.signOut")}
                </AuthClientSignOut>
              </li>
            )}
          </ul>
          <MobileMenuContacts></MobileMenuContacts>
        </nav>
      </CSSTransition>
    </React.Fragment>
  )
}

export { MobileMenuActions }
