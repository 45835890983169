import React from "react"
import cx from "clsx"

import styles from "./navigation.module.scss"

function Navigation({ children, isMobileMenuScrollRight, isWithoutScenes }) {
  return (
    <nav
      className={cx(styles["navigation"], {
        [styles["gradient-right"]]: !isWithoutScenes && !isMobileMenuScrollRight,
        [styles["gradient-left"]]: !isWithoutScenes && isMobileMenuScrollRight,
        [styles["no-scenes"]]: isWithoutScenes,
      })}
    >
      {children}
    </nav>
  )
}

export { Navigation }
