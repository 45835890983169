import { scroller } from "react-scroll"

function scrollToTarget(id) {
  const header = document.querySelector("#header")
  let headerHeight = null
  if (header) {
    headerHeight = header.getBoundingClientRect().height
  }

  function resetStyles(body) {
    body.style.width = ""
    body.style.height = ""
    body.style.overflowY = ""
    body.style.position = ""
    body.style.top = ""
  }

  function restoreScroll(body) {
    const scrollY = body.style.top
    resetStyles(body)
    window.scrollTo(0, parseInt(scrollY || "0") * -1)
  }

  const body = document.body

  restoreScroll(body)

  scroller.scrollTo(`${id}`, {
    duration: 500,
    hashSpy: false,
    smooth: true,
    spy: true,
    isDynamic: true,
    offset: -headerHeight, // Scrolls to element + 50 pixels down the page
  })
}

export { scrollToTarget }
