import { useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import cx from "clsx"

import { useToggleSelector, isMobileDevice } from "@app/utils"

import styles from "./modal-line.module.scss"

interface ModalLineProps {
  open: boolean
  text: string
  approve: string
  cancel: string
  position?: "up" | "down" | "left" | "right" | "up-left" | "up-right" | "down-left" | "down-right"
  handleApprove: () => void
  handleCancel: () => void
  isMobileType?: boolean
  className?: string
}

const ModalLine = ({
  open = false,
  text,
  approve,
  cancel,
  position = "down",
  handleApprove,
  handleCancel,
  isMobileType = false,
  className,
}: ModalLineProps) => {
  const isMobile = isMobileDevice()
  const rootEl = useRef(null)
  const { isDragActionDown, handleTouchStart, handleTouchMove, handleTouchEnd } = useToggleSelector()

  useEffect(() => {
    if (!isMobile) {
      const onClickOut = (e) => {
        if (rootEl && rootEl.current) {
          if (open && !rootEl.current.contains(e.target)) {
            handleCancel()
          }
        }
      }
      document.addEventListener("click", onClickOut)

      return () => document.removeEventListener("click", onClickOut)
    }
  }, [open])

  function handleTouchCloseEnd(e) {
    handleTouchEnd(e)

    if (isDragActionDown) {
      handleCancel()
    }
  }

  if (!open) return null

  const modal = isMobileType ? (
    createPortal(
      <aside
        className={cx(styles["blackout"], {
          [styles["open"]]: open,
          [styles["mobile-type"]]: isMobileType,
        })}
      >
        <div className={styles["back"]} onClick={handleCancel}></div>
        <div className={cx(styles["modal"])}>
          {isMobileType && (
            <div
              className={styles["line-close"]}
              onTouchStart={(e) => {
                handleTouchStart(e)
              }}
              onTouchMove={(e) => {
                handleTouchMove(e)
              }}
              onTouchEnd={(e) => {
                handleTouchCloseEnd(e)
              }}
            ></div>
          )}
          <p className={styles["text"]}>{text}</p>
          <div className={styles["buttons"]}>
            <span onClick={handleApprove} className={styles["approve"]}>
              {approve}
            </span>
            <span onClick={handleCancel} className={styles["cancel"]}>
              {cancel}
            </span>
          </div>
        </div>
      </aside>,
      document.getElementById("modal")
    )
  ) : (
    <div ref={rootEl} data-position={position} className={cx(styles["root"], className)}>
      <div className={styles["inner"]}>
        <p className={styles["text"]}>{text}</p>
        <div className={styles["buttons"]}>
          <span onClick={handleApprove} className={styles["approve"]}>
            {approve}
          </span>
          <span onClick={handleCancel} className={styles["cancel"]}>
            {cancel}
          </span>
        </div>
      </div>
    </div>
  )

  return modal
}

export { ModalLine }
