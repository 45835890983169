import React from "react"

function useMobileMenuHorizontalScroll() {
  const mobileMenuRef = React.useRef()
  const [isMobileMenuScrollRight, setIsMobileMenuScrollRight] = React.useState(false)

  function handleScrollMobileMenu() {
    const mobileMenuWidth = mobileMenuRef.current.getBoundingClientRect().width
    const mobileMenuScrollFromLeft = mobileMenuRef.current.scrollLeft

    if (mobileMenuWidth - mobileMenuScrollFromLeft < 0) {
      setIsMobileMenuScrollRight(true)
    } else {
      setIsMobileMenuScrollRight(false)
    }
  }

  return { mobileMenuRef, isMobileMenuScrollRight, handleScrollMobileMenu }
}

export { useMobileMenuHorizontalScroll }
